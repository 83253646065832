/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <div class="layout-container"
        style={{
          margin: `0 auto`,
          padding: `0 1.0875rem 1.45rem`,
          width: `100%`,
        }}
      >
      <aside class="nav-links" ><Header /></aside>
      <main style={{ width: `100%`,}}>{children}</main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
