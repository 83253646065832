import { Link } from "gatsby"
import React from "react"

const HeaderLinks = () => (
	<nav>
		<ul>
			<li><Link to="/punk">Punk</Link></li>
			<li><Link to="/processions">Processions</Link></li>
			<li><Link to="/raw-streets">Documentary photography</Link></li>
			<li><Link to="/contrafotografia">Contrafotografía</Link></li>
			<li id="last"><Link to="/agentsdelamort">Agentsdelamort</Link></li>
		</ul>
	</nav>
)

export default HeaderLinks