import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import HeaderLinks from "./headerLinks"

const Header = ({ siteTitle }) => (
  <header style={{
        width: `100%`
      }}>
    <div
    >
      <ul>
      <h1 style={{ "margin": "0.5em 0" }}>
        <li><Link to="/">
          {siteTitle}
        </Link></li>
      </h1>

      <HeaderLinks />
    </ul>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `David Garcerán`,
}

export default Header
